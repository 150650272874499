<template>
  <div>
    <v-card max-width="800">
      <v-data-table :headers="headers" :items="roles" class="pt-3">
        <template #top>
          <v-toolbar flat color="white">
            <v-card-title class="pl-0 pt-0"> Roles </v-card-title>

            <v-spacer />

            <v-btn
              v-if="userCanCreateRoles"
              color="primary"
              dark
              class="mb-2"
              @click="isFormDialogVisible = true"
            >
              New Role
            </v-btn>
          </v-toolbar>
        </template>
        <template #item.actions="{ item }">
          <v-icon small class="mr-2" @click="openFormDialog(item)">
            $mdi-pencil
          </v-icon>
          <v-icon
            v-if="userCanDeleteRoles"
            small
            @click="openDeleteConfirmationDialog(item)"
          >
            $mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <RolesFormDialog
      v-if="isFormDialogVisible"
      :title="formTitle"
      :is-submitting="isSubmitting"
      :error-message="errorMessage"
      :role="roleToEdit ? roleToEdit : undefined"
      @cancel="closeFormDialog"
      @save="save"
    />

    <ApDialog
      v-model="isDeleteConfirmationDialogVisible"
      title="Are you absolutely sure?"
      :actions="dialogDeleteActions"
      @cancel="closeDeleteConfirmationDialog"
      @confirm="removeRole"
    >
      <template #content>
        <div class="pa-3">
          This action <b>cannot</b> be undone. This will permanently delete the
          <b>{{ roleToDelete.name }}</b> role.
        </div>
      </template>
    </ApDialog>
  </div>
</template>
<script>
import ApDialog from '@/components/common/ApDialog'
import RolesFormDialog from './components/roles/RolesFormDialog'
import { mapGetters } from 'vuex'
import { getRoles, deleteRole, updateRole, createRole } from '@/api/admin/roles'

export default {
  components: {
    ApDialog,
    RolesFormDialog,
  },
  data() {
    return {
      roles: [],
      roleToEdit: null,
      roleToDelete: null,
      errorMessage: '',
      isSubmitting: false,
      isDeleteConfirmationDialogVisible: false,
      isFormDialogVisible: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['userCanCreateRoles', 'userCanDeleteRoles']),
    formTitle() {
      return this.roleToEdit ? 'Edit Role' : 'New Role'
    },
    dialogDeleteActions() {
      return [
        {
          event: 'cancel',
          label: 'Cancel',
          text: true,
        },
        {
          event: 'confirm',
          label: 'I understand the consequences, delete this role',
          color: 'error',
          loading: this.isSubmitting,
        },
      ]
    },
  },
  async created() {
    this.roles = await getRoles()
  },
  methods: {
    openFormDialog(role) {
      this.roleToEdit = role
      this.isFormDialogVisible = true
    },
    closeFormDialog() {
      this.isFormDialogVisible = false
      this.roleToEdit = null
    },
    openDeleteConfirmationDialog(role) {
      this.roleToDelete = role
      this.isDeleteConfirmationDialogVisible = true
    },
    closeDeleteConfirmationDialog() {
      this.roleToDelete = {}
      this.isDeleteConfirmationDialogVisible = false
    },
    save(formData) {
      if (this.roleToEdit) {
        this.updateRole(formData)
      } else {
        this.createRole(formData)
      }
    },
    async createRole(formData) {
      try {
        this.isSubmitting = true
        const roleCreated = await createRole(formData)

        this.roles = [...this.roles, roleCreated]

        this.closeFormDialog()
        this.roles = await getRoles()
      } catch (error) {
        this.handleAPIError(error)
      } finally {
        this.isSubmitting = false
      }
    },
    async updateRole(formData) {
      try {
        this.isSubmitting = true
        const roleUpdated = await updateRole(this.roleToEdit.id, formData)

        this.roles = this.roles.map((el) =>
          el.id === roleUpdated.id ? roleUpdated : el
        )

        this.closeFormDialog()
        this.roles = await getRoles()
      } catch (error) {
        this.handleAPIError(error)
      } finally {
        this.isSubmitting = false
      }
    },
    async removeRole() {
      try {
        this.isSubmitting = true
        await deleteRole(this.roleToDelete.id)

        this.roles = this.roles.filter((el) => el.id !== this.roleToDelete.id)

        this.closeDeleteConfirmationDialog()
        this.roles = await getRoles()
      } catch (error) {
        this.handleAPIError(error)
      } finally {
        this.isSubmitting = false
      }
    },
    handleAPIError(error) {
      const errorJson = error.response.data
      const errorInfo = errorJson['error']

      this.errorMessage = errorInfo['message']
    },
  },
}
</script>
