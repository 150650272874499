import http from '../http'
import { mapToArray, mapToObject } from '../helpers/mappers'

const ROLE_RESPONSE_PATHS = {
  id: 'id',
  name: 'name',
  description: 'description',
  permissions: 'permitted_actions',
}

const ROLE_REQUEST_PATHS = {
  name: 'name',
  description: 'description',
  permitted_actions: 'permissions',
}

export const getRoles = () => {
  return http.$get('/admin/roles').then((response) => {
    return mapToArray(response.data, ROLE_RESPONSE_PATHS)
  })
}

export const createRole = (payload) => {
  return http
    .$post('/admin/roles', mapToObject(payload, ROLE_REQUEST_PATHS))
    .then((response) => {
      return mapToObject(response, ROLE_RESPONSE_PATHS)
    })
}

export const updateRole = (roleId, payload) => {
  return http
    .$post(`/admin/roles/${roleId}`, mapToObject(payload, ROLE_REQUEST_PATHS))
    .then((response) => {
      return mapToObject(response, ROLE_RESPONSE_PATHS)
    })
}

export const deleteRole = (roleId) => {
  return http.$delete(`/admin/roles/${roleId}`)
}
