<template>
  <ApDialog
    :value="true"
    :title="title"
    :actions="actions"
    @cancel="$emit('cancel')"
    @save="$emit('save', form)"
  >
    <template #content>
      <div class="px-6">
        <v-text-field v-model="form.name" label="Name" hide-details />

        <v-text-field
          v-model="form.description"
          label="Description"
          hide-details
        />

        <div class="mt-6">Permitted Actions</div>

        <v-treeview
          v-model="form.permissions"
          selectable
          :items="appFunctionsToAssignToRoles"
          item-disabled="cannotBeChanged"
        />

        <v-alert v-if="errorMessage" dense text type="error" class="mx-5 my-0">
          {{ errorMessage }}
        </v-alert>
      </div>
    </template>
  </ApDialog>
</template>

<script>
import ApDialog from '@/components/common/ApDialog'
import { appFunctionsToAssignToRoles } from '../../utils/appFunctionsToAssignToRoles.js'
import { mapGetters } from 'vuex'

export default {
  components: {
    ApDialog,
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    errorMessage: {
      type: String,
      default: undefined,
    },
    role: {
      type: Object,
      default: () => {
        return {
          name: '',
          description: '',
          permissions: [],
        }
      },
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      appFunctionsToAssignToRoles,
      form: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['userCanUpdateRoles']),
    actions() {
      const actions = [
        {
          event: 'cancel',
          label: 'Cancel',
          color: 'primary',
          text: true,
          disabled: this.isSubmitting,
        },
      ]

      if (this.userCanUpdateRoles) {
        actions.push({
          event: 'save',
          label: 'Save',
          color: 'primary',
          text: true,
          loading: this.isSubmitting,
        })
      }

      return actions
    },
  },
  created() {
    this.form = { ...this.role }
  },
}
</script>
